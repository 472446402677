.container {
  position: relative;
  width: 100%;
  max-width: 582px;
  height: auto;
  margin: 0 auto;
  background: url("../../images/home/bannertextbox.png") no-repeat center center;
  background-size: contain;
  padding-top: 56.25%; /* Maintain the aspect ratio of the container */
}

.textOverlay {
  position: absolute;
  top: 48%;
  left: 69%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: left;
  width: 52%;
  padding: 0;
}

ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0;
}

.textOverlay li {
  font-size: 1.1vw;
  margin: 15px 0;
  padding-left: 30px;
  background: url("../../images/home/haloicon.png") no-repeat left center;
  background-size: 20px; /* Adjust size of the circle icon */
}

@media only screen and (max-width: 1440px) {
  .textOverlay li {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 993px) {
  .textOverlay li {
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 600px) {
  .textOverlay li {
    font-size: 3.5vw;
  }
}
