.boxSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.buttonContainer {
  width: 100%;
  text-align: center;
}

.customTextField {
  max-width: 600px;
  width: 100%;
}
.customTextField fieldset {
  border-radius: 0 !important;
  background-color: rgba(255, 0, 0, 0) !important;
  border: 1px solid #ccc !important;
}
.customTextField input {
  color: white !important;
}

.customTextField div {
  color: white !important;
}

.customButton {
  max-width: 500px;
  width: 100%; /* Ensure full width within the container */
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  border-radius: 50px !important;
  background-image: url("../../images/button/button.png") no-repeat;
  background-size: 100% 100%;
  box-shadow: none !important;
  height: 50px !important;
  font-size: 16px !important;
  color: white;
  font-weight: 700;
  font-family: "pingfangSmall";
}
.customButton:hover {
  background: linear-gradient(90deg, #2ba4a6 0%, #6fc3c4 100%);
}

.formContainer {
  flex-grow: 1;
  max-width: 500px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.formField {
  flex: 1;
  margin-right: 10px;
}

.formField:last-child {
  margin-right: 0;
}

.isRequired label::after {
  content: " *";
  color: red;
  margin-left: 0.2em;
}

@media (max-width: 1200px) {
  .formContainer {
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  .boxSection {
    align-items: center;
    width: 80%;
  }
  .formContainer {
    max-width: unset;
  }
}

@media (max-width: 500px) {
  .customTextField {
    font-size: 12px;
  }
  .customTextField fieldset {
    font-size: 12px;
  }
  .customTextField input {
    font-size: 15px !important;
  }

  .customTextField div {
    font-size: 12px;
  }
  .customTextField span {
    font-size: 12px;
  }
}
