/* applyLogin.module.css */
.container {
  display: flex;
  height: 100vh;
  width: 100%;
  background: url("../../images/pages/formbg.png") no-repeat center center;
  background-size: 100% 100%;
}

.leftSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
}
.leftSection img {
  max-width: 500px;
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 0 50px 100px;
  /* background: #eaf6f4; */
}

.loginTab {
  font-size: 40px;
  font-weight: 500;
  margin-top: 5rem;
}

.formInnerBox {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.customTextField {
  max-width: 500px;
  width: 100%;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.customTextField fieldset {
  border-radius: 0 !important;
  background-color: rgba(255, 0, 0, 0) !important;
  border: 1px solid #ccc !important;
}
.customTextField input {
  color: white !important;
}

.customButton {
  max-width: 500px;
  width: 100%; /* Ensure full width within the container */
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  border-radius: 50px !important;
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  box-shadow: none !important;
  height: 50px !important;
  font-size: 16px !important;
}
.customButton:hover {
  background: linear-gradient(90deg, #2ba4a6 0%, #6fc3c4 100%);
}

.error_msg {
  color: red;
}

@media (max-width: 1200px) {
  .formInnerBox {
    max-width: 350px;
  }
  .formSection {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    background-image: url(../../images/pages/Q&Abg.png);
    background-size: cover;
  }
  .formSection {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
  }

  .leftSection {
    height: 200px;
    background-size: cover;
    padding: 10px;
  }
  .formSection {
    padding: 0;
  }
}
