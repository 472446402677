.container {
  padding-top: 200px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.boxContainer {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 每行 2 個欄位 */
  gap: 30px; /* 欄位之間的間距 */
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  box-sizing: border-box;
  justify-items: center;
}
.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}
.result {
  margin-top: 50px;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .gridContainer {
    gap: 10px;
  }
  .item {
    width: 90%;
  }
}
