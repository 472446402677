.customButton {
  max-width: 150px;
  width: 100%; /* Ensure full width within the container */
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  border-radius: 50px !important;
  background: linear-gradient(90deg, #8361f4 0%, #80d4e6 100%);
  box-shadow: none !important;
  height: 35px !important;
  font-size: 15px !important;
}
.customButton:hover {
  opacity: 0.8;
}

.customInput fieldset {
  border-radius: 0 !important;
  background-color: rgba(255, 0, 0, 0) !important;
  border: 1px solid #ccc !important;
}
.customInput input {
  color: white !important;
}
