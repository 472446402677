.container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  background-color: #05254c;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;

  color: white;
}

.remarks_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 150px;
  width: 100%;
  background-color: #0c3467;
  color: white;
}
.remarks_content {
  width: fit-content;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 1rem;
}

.logo_wrapper {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
}

.logo {
  width: 350px;
  height: auto;
}

.footer_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 10px 3rem 10px;
}

.footer_content h1 {
  font-size: 4vw;
}
.footer_content h2 {
  font-size: 3vw;
}

.container a {
  color: inherit;
  cursor: pointer;
}

.container a:hover {
  color: #bdbdbd;
}

.section1_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container h3 {
  font-size: 18px;
  font-weight: normal;
}
.container h2 {
  font-size: 25px;
}

.container p {
  font-size: 15px;
  font-weight: normal;
}

.social_logo {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.column1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  white-space: nowrap;
}

.page_link {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding-bottom: 5rem;
}

.divide {
  padding-left: 1rem;
  padding-right: 1rem;
}

.column3_1 {
  text-align: right;
}

.divider {
  width: 100%;
  border-top: 1px solid #054749;
  opacity: 1;
}

.container_mobile {
  display: flex;
  flex-direction: column;
  background-image: url(../../images/footer/footer.png);
  background-size: cover;
  color: #0d4b4b;
  font-weight: 600;
}

.section1_container_mobile a {
  color: inherit;
  cursor: pointer;
}

.section1_container_mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
}

.logo_mobile {
  width: 100px;
  height: auto;
}

.social_logo_mobile {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.page_link_mobile {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.page_link_mobile a {
  color: #0d4b4b;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
.page_link_mobile span {
  white-space: nowrap;
}

.social_logo_wrapper {
  display: flex;
  justify-content: center;
  padding: 0 0 1rem 0;
}

/* @media mobile  */
@media only screen and (max-width: 960px) {
  .column1 {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) and (min-width: 600px) {
  .page_link {
    white-space: unset;
  }
}

@media only screen and (max-width: 1024px) {
  .remarks_wrapper {
    padding: 2rem 1rem 2rem 1rem;
  }
  .page_link span {
    font-size: 3vw;
  }
  .divide {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4px;
  }

  .footer_content h1 {
    font-size: 5vw;
  }
  .footer_content h2 {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 500px) {
  .footer_content h1 {
    font-size: 6vw;
  }
  .footer_content h2 {
    font-size: 4vw;
  }
  .divider {
    width: 100%;
  }
  .logo {
    width: 200px;
  }
}
