.featuresSection {
  max-width: 1800px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}

.rightSection h3,
.leftSection h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffd800;
}
.centerContent h3 {
  font-weight: 600;
}

.featuresSection p {
  font-weight: 600;
}

.leftSection,
.rightSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.centerSection {
  position: relative;
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.centerImage {
  width: 100%;
  max-width: 400px; /* Adjust based on your needs */
}

.centerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.featureBox {
  position: relative;
}

/* featureBox first item left position */
.leftSection .featureBox:nth-child(1) {
  left: 45px;
}

.leftSection .featureBox:nth-child(3) {
  left: -45px;
}

.rightSection .featureBox:nth-child(1) {
  left: -45px;
}

.rightSection .featureBox:nth-child(3) {
  left: 45px;
}

.featureImage {
  width: 100%;
  height: auto;
}

.featureContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  width: 300px;
}

.leftSection .featureContent {
  text-align: right;
}
.rightSection .featureContent {
  text-align: left;
}

h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffd700; /* Golden yellow for emphasis */
}

p {
  font-size: 1.1rem;
}
.featuresSection_mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

.featuresSection_mob .centerImage {
  max-width: 250px;
  margin-bottom: 2rem;
}

.featuresSection_mob .featureBox {
  position: relative;
  width: 90%;
  max-width: 300px;
  border-radius: 8px; /* Optional: For a rounded look */
  text-align: center;
}

.featuresSection_mob h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffd800;
  margin-bottom: 0.5rem; /* Add space below title */
}

.featuresSection_mob p {
  font-size: 1rem;
  color: #ffffff; /* Ensure text color is readable */
  margin: 0;
}

.centerImage_mob {
  position: relative;
  width: 100%;
  max-width: 250px;
}

.centerContent_mob {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  font-weight: bold;
}

.centerContent_mob h3 {
  color: white;
  margin: 0;
  font-size: 1.2rem; /* Adjust font size as needed */
}

.featuresSection_mob .featureBox {
  margin-top: 1rem;
}

/* media mobile */
@media (max-width: 1200px) {
  .featureContent {
    top: 60%;
    width: 215px;
  }
  .featureContent h3 {
    font-size: 1.2rem;
  }
  .featureContent p {
    font-size: 1rem;
  }

  .alignRight .featureContent {
    text-align: right;
    left: 35% !important;
  }

  .alignLeft .featureContent {
    text-align: left;
    left: 65% !important;
  }
}

@media (max-width: 600px) {
  .centerSection {
    margin-bottom: 1rem;
  }
  .featureContent {
    width: 210px;
  }

  .alignRight .featureContent {
    text-align: right;
    left: 50% !important;
  }

  .alignLeft .featureContent {
    text-align: left;
    left: 50% !important;
  }
}
