.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../images/quoteSection/Part-3bg.png");
  background-size: 100% 100%;
  background-position: center -67px;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;
}

.item_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Ensure the content is centered */
  background-image: url("../../images/quoteSection/part-3textbox.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1000px;
  height: 640px;
  padding: 40px;
  box-sizing: border-box;
  color: #081527;
  align-items: end;
}
.item_wrapper h3 {
  font-size: 1.8rem;
  background: linear-gradient(to right, #00a9e0, #6441a5);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
  left: -18px;
}

.item {
  width: 100%;
  max-width: 400px;
  position: relative;
  left: -100px;
}
.quote_img {
  width: 50px;
  position: relative;
  right: 242px;
  top: -28px;
}

.item p {
  text-align: justify;
  font-size: 1.2rem;
  font-weight: 600;
}

.checklist {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  text-align: justify;
  font-weight: bold;
}

.checklist li {
  display: flex;
  align-self: flex-start;
  font-size: 1rem;
  color: #031228;
  margin-bottom: 15px;
  line-height: 1.5;
  align-items: start; /* Ensure all items align vertically */
}

.checkbox {
  width: 24px;
  height: 24px;
  min-width: 24px; /* Ensure consistent width */
  min-height: 24px; /* Ensure consistent height */
  background: url("../../images/icon/tickicon.png") no-repeat center center;
  background-size: contain;
  margin-right: 10px;
}

/* media mobile  */

@media (max-width: 850px) and (min-width: 501px) {
  .item_wrapper h3 {
    left: unset;
    font-size: 1.5rem;
  }
  .container {
    height: 60vh;
  }

  .item_wrapper p {
    font-size: 1.1rem;
  }
  .item_wrapper {
    align-items: center;
    background-image: url("../../images/quoteSection/part-3textbox.png");
    background-size: contain;
    padding: 1rem;
  }
  .item {
    left: 133px;
  }
  .checklist {
    padding: 0;
  }
}

@media (max-width: 500px) {
  .container {
    height: 80vh;
  }

  .quote_img {
    width: 40px;
    position: relative;
    right: 18px;
    top: -24px;
  }

  .item_wrapper h3 {
    font-size: 16px;
    width: 218px;
    text-align: left;
    position: relative;
    left: 174px;
    white-space: normal;
  }

  .item_wrapper p {
    font-size: 14px;
    width: 150px;
    text-align: left;
    position: relative;
    left: 203px;
  }
  .item_wrapper {
    align-items: center;
    background-image: url(../../images//pages/mobliepart3-box.png);
    background-size: 100% 100%;
    background-position: -9px 0px;
    background-repeat: no-repeat;

    padding: 1rem;
  }
  .item {
    top: 47px;
    left: 22px;
  }
}
.checklist {
  padding: 0;
  line-height: 12px;
  width: 346px;
  position: relative;
  left: 14px;
}
.checklist li {
  font-size: 14px;
  margin-bottom: 10px;
}

.checkbox {
  margin-right: 2px;
}

@media (max-width: 400px) {
  .item {
    left: 0px;
  }

  .quote_img {
    width: 35px;
    right: -1px;
    top: -14px;
  }
}
@media (max-width: 375px) {
  .item {
    left: 0px;
  }
  .item_wrapper h3 {
    left: 164px;
  }
  .item_wrapper p {
    left: 194px;
  }
  .checklist li {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
