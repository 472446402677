.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.card_container {
  display: flex;
  width: 50%;
  max-width: 300px;
  height: 400px;
  margin: 20px;
  cursor: pointer;
}
.card_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.card_container:hover {
  transform: scale(1.1);
  transition: transform 0.2s;
}

.card_wrapper h2 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 50px;
}
.card_wrapper img {
  width: auto;
  height: 150px;
  object-fit: cover;
}

/* mobile for all responsive */
@media only screen and (max-width: 768px) {
  .card_container {
    margin-top: 0;
    width: 100%;
    height: 250px;
  }
  .card_wrapper h2 {
    font-size: 20px;
  }
  .card_wrapper img {
    height: 100px;
  }
}
