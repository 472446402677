.user-control-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
}

.toRight {
  width: 100%;
  text-align: right;
  position: relative;
  /* bottom: 45px; */
}
