.container {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.header_container {
  width: 100%;
  display: flex;
}

.header_title h2 {
  color: #47cfd7;
  text-shadow: 2px 2px 2px #92cecc85;
  font-weight: bold;
}
.search_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.searchField {
  width: 250px;
}

.actionBtn {
  border-radius: 12px;
}

.circleBtn {
  border-radius: 50%;
  margin: 4px;
}
