.container {
  width: 100%;
  height: auto;
  background-image: url(../../images/pages/Q&Abg.png);
  background-size: cover;
  background-position: center 0px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 200px;
  padding-bottom: 5rem;
}

.container h2 {
  margin-bottom: 20px;
  font-weight: 600;
  color: white;
}

.content {
  font-size: 1.2rem;
  width: 70%;
}

@media (max-width: 500px) {
  .container {
    padding-top: 5rem;
  }
  .content {
    font-size: 16px;
    width: 90%;
  }
}
