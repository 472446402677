.container {
  display: flex;
  width: 100%;
  /* height: clamp(800px, 100%, 100vh); */
  place-content: center;

  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;

  z-index: 10;
  /* clip-path: polygon(0 0%, 100% 0, 100% 89%, 0 100%); */
}

.videoPic {
  background-image: url(../../images/home/videobg.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.videoBg_container {
  width: 100%;
  position: absolute;
  height: 120vh;
  top: 0;
  z-index: -1;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background: linear-gradient(to bottom, rgb(12 37 78), rgba(0, 0, 0, 0));
  z-index: 10;
  overflow: hidden;
}

.container_mob {
  display: flex;
  width: 100%;
  place-content: center;
  position: relative;
  height: 118vh;
  z-index: 10;
}

@media only screen and (max-width: 992px) {
  .container {
    height: auto;
  }
  .videoBg_container {
    height: 150vh;
  }
  .container_mob {
    height: auto;
  }
  .overlay {
    height: 200vh;
  }
  .videoPic {
    height: 150vh;
  }
}

@media only screen and (max-width: 850px) and (min-width: 480px) {
  .videoPic {
    height: 120vh;
  }
}

@media only screen and (max-width: 500px) {
  .videoPic {
    height: 120vh;
  }
}
