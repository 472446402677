.container {
  width: 100%;
  height: 100vh;
  background-image: url(../../images/pages/Q&Abg.png);
  background-size: cover;
  background-position: center 0px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 200px;
  overflow-y: auto;
}

.container h2 {
  margin-bottom: 20px;
}
