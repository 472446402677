.input_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(300px, 100%, 700px);

  align-self: center;
  margin-bottom: 100px;
  padding: 20px;
}

.input_container div,
.input_container p,
.input_container span,
.input_container input,
.input_container button,
.input_container label,
.input_container li,
.input_container h1,
.input_container h2,
.input_container h3,
.input_container h4,
.input_container h5 {
  font-family: "pingfangSmall" !important;
  font-weight: 600;
}

.signature_bg {
  background-image: url(../../images/account/bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.input-field {
  width: 100%;
}

.input-field label,
.input-field input {
  font-family: "pingfangSmall" !important;
}

.main_tone h2,
.main_tone h1,
.main_tone h3,
.main_tone p,
/* .main_tone span, */
.main_tone label {
  color: #108d91 !important;
}
.main_tone span {
  color: #108d91;
}

.contract-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contract-input-container {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  justify-content: center;
}
.contract-input-container .MuiTextField-root {
  width: 40%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.contract-input-container .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.contract-input-container .button-container button {
  margin: 1rem;
}
.contract-preview-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.contract-preview-iframe {
  max-width: 100%;
  height: 1000px;
}

.contract-sign-canvas {
  padding-left: 1rem;
  padding-right: 1rem;
}
