.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container > h3 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.stepsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  flex: 1;
}

.stepNumber {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 41px;
  width: 179px;
  font-size: 1.2rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: 600;
}

.step:nth-child(1) .stepNumber {
  background-color: white;
  color: #133e7c;
}
.step:nth-child(2) .stepNumber {
  background-color: #1e4684;
}
.step:nth-child(3) .stepNumber {
  background-color: #0542a1;
}

.step:nth-child(1) .stepText {
  color: #133e7c;
}
.step:nth-child(2) .stepText {
  color: white;
}
.step:nth-child(3) .stepText {
  color: white;
}

/* media mobile */
@media (max-width: 768px) {
  .stepNumber {
    width: 25px;
    height: 25px;
    font-size: 15px;
  }
  .stepText {
    font-size: 15px;
    width: 120px;
    height: 30px;
  }
}
