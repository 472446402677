.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.policy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding: 20px;
  margin: 20px;
}
