.feature-paper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.feature-paper-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  place-content: center;
}

.feature-paper-item {
  width: 260px;
  border: 0;
  border-radius: 12px;
  box-shadow: 0 0 13px 1px rgb(206 206 206 / 40%);
  padding: 40px 10px 20px 10px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #074445;
  margin-left: 10px;
  margin-right: 10px;
}
.feature-paper-item img {
  width: 150px;
  height: auto;
}

.feature-paper-items-wrapper .feature-paper-item:nth-child(3) img {
  padding-top: 20px;
  padding-bottom: 26px;
}
.feature-paper-items-wrapper .feature-paper-item:nth-child(4) img {
  padding-top: 5px;
  padding-bottom: 16px;
}

.feature-paper-item p {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.feature-paper-items-wrapper .feature-paper-item:nth-child(even) {
  margin-top: 100px;
}

.largeamount .feature-paper-items-wrapper .feature-paper-item:nth-child(1) img {
  padding-top: 35px;
  padding-bottom: 30px;
}
.largeamount .feature-paper-items-wrapper .feature-paper-item:nth-child(3) img {
  padding-top: 0px;
  padding-bottom: 14px;
}
.largeamount .feature-paper-items-wrapper .feature-paper-item:nth-child(4) img {
  padding-top: 10px;
  padding-bottom: 28px;
}

.mortgage .feature-paper-items-wrapper .feature-paper-item:nth-child(1) img {
  padding-top: 8px;
  padding-bottom: 15px;
}
.mortgage .feature-paper-items-wrapper .feature-paper-item:nth-child(3) img {
  padding-top: 5px;
  padding-bottom: 30px;
}
.mortgage .feature-paper-items-wrapper .feature-paper-item:nth-child(2) img {
  padding-top: 10px;
  padding-bottom: 10px;
}
.mortgage .feature-paper-items-wrapper .feature-paper-item:nth-child(4) img {
  padding-top: 0px;
  padding-bottom: 12px;
}

/***** mobile *****/
.feature-paper-item-mobile {
  width: 100%;
  border: 0;
  border-radius: 12px;
  /* box-shadow: 0 0 13px 1px rgb(206 206 206 / 40%); */
  padding: 40px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #074445;
  /* margin-left: 10px;
  margin-right: 10px; */
  text-align: center;
}
.feature-paper-item-mobile img {
  width: 100%;
  height: auto;
}

.feature-paper-carousel {
  width: fit-content;
}

@media only screen and (max-width: 375px) {
  .feature-paper-carousel .p-carousel {
    width: 320px !important;
  }
}
