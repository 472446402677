.floatBtnList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;

  position: fixed;
  z-index: 99999;
  right: 36px;
  bottom: 132px;
  align-items: center;
}

.floatBtnList-item {
  width: fit-content;
  /* margin-bottom: 10px; */
  transition: 0.2s ease-in;
  scale: 0.75;
  opacity: 0.8;
}

.floatBtnList-item:hover {
  transform: scale(1.1);
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .floatBtnList {
    bottom: 73px;
    right: 0;
  }
}
