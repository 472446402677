.login {
  margin-top: 10rem;
}

.logo {
  display: flex;
  justify-content: center;
}

.login_input_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 5rem;
}
.login-container {
  height: 66vh;
}

.login-container .input_container {
  background-color: white;
  padding: 1.2rem;
  border-radius: 17px;
}
