/* applyLogin.module.css */
.container {
  display: flex;
  height: 100vh;
  width: 100%;
  background: url("../../images/pages/formbg.png") no-repeat center center;
  /* background: url("../../images/applyLogin/bg.png") no-repeat center center; */
  background-size: 100% 100%;
}

.leftSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
}
.leftSection img {
  max-width: 500px;
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 0 50px 100px;
  /* background: #eaf6f4; */
}

.applyTab,
.loginTab {
  font-size: 40px;
  font-weight: 500;
  cursor: pointer;
}

/* media 1024*768 */
@media (max-width: 1200px) {
  .formInnerBox {
    max-width: 350px;
  }
  .formSection {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    background-image: url(../../images/pages/Q&Abg.png);
    background-size: cover;
  }
  .formSection {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
  }

  .leftSection {
    height: 200px;
    background-size: cover;
    padding: 10px;
  }
  .formSection {
    padding-top: 100px;
  }
}
