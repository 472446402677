.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #0b2239; /* Dark background color similar to the image */
  width: 200px;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
}

.image {
  width: 100%;
  margin-bottom: 10px;
}

.image img {
  width: auto;
  height: 80px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: white;
}

.text {
  font-size: 14px;
  color: white; /* Lighter text color for readability */
}
