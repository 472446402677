.remarks {
  font-size: 11px;
  padding-top: 1rem;
}

.container {
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 40px;
}

.item_title {
  white-space: nowrap;
}

.item_title span {
  margin-right: 1rem;
}

.box_container {
  width: 100%;
  padding: 80px 50px 60px 50px;
}

.money_input {
  display: flex;
  background-color: #fafafa;
  /* border: 1px solid rgb(204, 204, 204); */
  border-radius: 6px;
  width: clamp(150px, 60%, 350px);
  justify-content: center;
  align-items: self-end;
  height: 2rem;
}
.money_input span {
  color: black;
  align-self: center;
  z-index: 5;
  font-size: 1rem;
}
.money_input input {
  background-color: #fafafa;
  border: 0;
  border-radius: 6px;
  font-weight: 600;

  text-align: end;
  padding: 0;
  margin: 0;
  width: 68%;
  font-size: 1.5rem;
}

.money_input input:focus-visible {
  outline: 0px;
}

.row_container {
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row_container img {
  margin-top: -7px;
  padding-right: 10px;
}

.space {
  height: 30px;
}

@media only screen and (max-width: 800px) {
  .container {
    background: #fafafa;
    border-radius: 17px;
  }
  .money_input,
  .money_input input {
    background: #fafafa;
  }
}

@media only screen and (max-width: 500px) {
  .box_container {
    padding: 37px 28px 53px;
  }
}
