.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container h4 {
  font-size: 1.7rem;
  font-weight: 600;
}

.banner_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  background-image: url(../../images/pages/banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 55vh;

  @media screen and (max-width: 500px) {
    height: 100vh;
    background-image: url(../../images/pages/otherpage-mobile-banner.png);
    background-size: 100% 100%;
  }

  @media screen and (min-width: 1260px) {
    height: 76vh;
  }
  @media screen and (min-width: 1440px) {
    height: 61vh;
  }

  @media screen and (min-width: 1920px) {
    height: 70vh;
  }
}

.banner_content {
  width: 26%;
  line-height: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  height: 100%;

  position: relative;
  top: 18%;
  left: -9%;

  @media screen and (max-width: 500px) {
    justify-content: start;
    width: 80%;
    line-height: 1.5;
  }
}

.page_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: url("../../images/pages/Blogbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section1 {
  color: white;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  width: 100%;
  background-image: url(../../images/home/Part-2bg.png);
  background-size: contain;
  background-position: center 0px;
  background-repeat: no-repeat;
  min-height: 80vh;
  overflow: hidden;
  position: relative;
}

/* media mobile */

@media screen and (max-width: 500px) {
  .section1 h2 {
    font-size: 1.3rem;
  }
  .banner_content div {
    font-size: 1rem;
  }
  .banner_content {
    padding: 1rem;
    left: 0;
  }
}
