.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  padding-left: 100px;
  background: url(../../images/loanProductSwiper/animationbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px -192px;
}

.iconText {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.iconText img {
  width: 35px;
  margin-right: 1em;
}
.left_container {
  width: 45%;
  padding-top: 200px;
  padding-left: 10%;
}

.left_container h1 {
  font-size: 57px;
  font-weight: 600;
}
.left_container h4 {
  font-size: 22px;
  font-weight: 300;
}

.right_container {
  width: 55%;
}

/* @media mobile version responsive */

@media (min-width: 601px) and (max-width: 850px) {
  .container {
    flex-direction: column;
    padding: 0;
  }
  .left_container {
    padding-left: 3vw;
    top: 150px;
    position: absolute;
    left: 0;
    width: fit-content;
  }
  .right_container {
    width: 100%;
    position: relative;
    right: -293px;
  }
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    background: url(../../images/loanProductSwiper/animationbg_mob.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 5px 119px;
  }
  .right_container {
    width: 100%;
  }
  .left_container {
    width: 100%;
    position: relative;
    padding-top: 0;
    scale: 0.7;
    left: -56px;
    top: 252px;
  }
}

@media only screen and (max-width: 500px) {
  .box_container {
    padding: 37px 28px 53px;
  }
  .left_container h4 {
    font-size: 18px;
    font-weight: 300;
  }
}
@media only screen and (max-width: 500px) {
  .left_container {
    top: -540px;
  }
  .left_container h4 {
    font-size: 14px;
    font-weight: 300;
  }

  .left_container h1 {
    font-size: 40px;
  }
}
