.heading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.heading-container {
  max-width: 500px;
}

.heading-container img {
  width: 150px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
