.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.container p,
.container span,
.container div,
.container label .container h5 {
  font-family: "pingfangSmall" !important;
}

.searchWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 20px;
  z-index: 99;
}

.search_container input {
  width: 100%;
  border: 0;
  box-shadow: 0px 0px 20px 0px #2cced175;
}

.searchWrapper img {
  width: 30px;
  height: 30px;
  margin-top: -10px;
  margin-left: -4px;
}

.filterContainer {
  display: flex;
  width: 80%;
  margin-bottom: 20px;
}

.filterList {
  width: 20%;
}

.filterList .MuiListItem-root {
  cursor: pointer;
}

.filterList .MuiListItemButton-root:hover,
.filterList .MuiListItemButton-root .Mui-selected {
  /* background-color: #d0eef0 !important; */
}

.filterItem {
}

.filterItem span {
  color: #10463e;
  font-size: 18px;
  font-weight: 600;
}

.postsContainer {
  width: 80%;
}

.postCard {
  margin-bottom: 20px;
}

.paginationContainer {
  margin-top: 20px;
}
.post {
  cursor: pointer;
  height: 350px;
}
.post:hover {
  opacity: 0.8;
}

/* @media mobile responsive filteritem span */
@media only screen and (max-width: 768px) {
  .filterList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .filterContainer {
    flex-direction: column;
    align-items: center;
  }
  .filterItem span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .filterItem {
    padding: 0 !important;
  }
  .filterList span {
    font-size: 16px;
  }
  .container {
    padding: 0 !important;
  }
  .post p {
    font-size: 12px;
  }
}
