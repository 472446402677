.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../../images/home/backgroundcolor.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container h4 {
  font-size: 1.7rem;
  font-weight: 600;
}

.banner_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  background-image: url(../../images/pages/banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 55vh;

  @media screen and (min-width: 1260px) {
    height: 76vh;
  }
  @media screen and (min-width: 1440px) {
    height: 61vh;
  }

  @media screen and (min-width: 1920px) {
    height: 70vh;
  }
}

.banner_content {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  height: 100%;

  position: relative;
  top: 18%;
  left: -9%;
}

.page_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  /* background: url("../../images/pages/Blogbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}

.section1 {
  color: white;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  width: 100%;
  background-image: url(../../images/home/Part-2bg.png);
  background-size: contain;
  background-position: center 0px;
  background-repeat: no-repeat;
  min-height: 80vh;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 500px) {
  .section1 h2 {
    font-size: 1.3rem;
  }
}
