.signCanvas {
  margin-top: 50px;
}

.sigContainer {
  max-width: 600px;
  min-width: 300px;
  min-height: 300px;
  background-color: #ffffff;
  border: 1px black solid;
}

.sigPad {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.sigImage {
  background-color: rgba(255, 255, 255, 0);
}

.signCanvas-btn {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
}
