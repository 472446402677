.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;

  background-image: url(../../images/home/part-1bg.png);
  background-size: contain;
  background-position: center 0px;
  background-repeat: no-repeat;
}

.section1 {
  color: white;
  text-align: center;
  margin-top: 5rem;
}

.section1 h2 {
  font-weight: 700;
}

/* media mobile */
@media (max-width: 850px) {
  .container {
    margin-bottom: 1rem;
  }
  .section1 h5 {
    font-size: 4vw;
  }
  .section1 h2 {
    font-size: 5vw;
  }
}
@media (max-width: 500px) {
  .container {
    margin-bottom: 1rem;
  }
  .section1 h5 {
    font-size: 4vw;
  }
  .section1 h2 {
    font-size: 5vw;
  }
}
