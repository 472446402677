.approvalStatus {
  width: 100%;
  font-weight: bold;
}
.approvalStatus span {
  color: #108d91;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  justify-content: center;
}

.back_button {
  width: 30px;
  align-self: flex-start;
  transition: 0.3s;
  cursor: pointer;
}
.back_button:hover {
  opacity: 50%;
}

.logout_button {
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 100px;
  height: 35px;
  margin: 1rem;
  font-size: 1rem !important;
  font-weight: bold !important;
  border-radius: 70px !important;
}

.functional_button {
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 150px;
  max-height: 35px;
  margin: 1rem;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  border-radius: 75px !important;
}
.long_button {
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 100%;
  max-width: 300px;
  height: 45px;
  margin: 1rem;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  border-radius: 75px !important;
}

.sign_button {
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 80px;
  max-height: 35px;
  font-size: 1rem !important;
  font-weight: bold !important;
  border-radius: 75px !important;
}

.save_sign_button {
  background-color: #58ac96 !important;
  width: 180px;
  max-height: 50px;
  margin: 1.1rem !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  border-radius: 75px !important;
}

.update_button {
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 80px;
  max-height: 35px;

  font-size: 1rem !important;
  font-weight: bold !important;
  border-radius: 75px !important;
}

.input_field {
  width: 100%;
  margin: 10px !important;
}

.error_msg {
  color: rgb(184, 31, 31);
}

.success_msg {
  color: #108d91;
}

.divider {
  width: 100%;
  border-top: 1px solid rgb(109, 109, 109);
}

.list_button {
  /* margin: 10px !important; */
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 60px;
  max-height: 35px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-size: 12px !important;
  border-radius: 50px !important;
}

.upload_button {
  margin: 0 !important;
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 60px;
  max-height: 35px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 75px !important;
}

.list_container {
  display: flex;
  max-height: 800px;
  min-width: 375px;
  overflow: auto !important;
}

.signCanvas_button {
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 100px;
  max-height: 35px;
  margin: 20px !important;

  font-size: 1rem !important;
  font-weight: bold !important;
  border-radius: 75px !important;
}

.list_button_changePw {
  margin: 10px !important;
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  width: 100px;
  max-height: 35px;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 75px !important;
}

@media only screen and (max-width: 500px) {
  .table th {
    font-size: 12px !important;
  }
}
