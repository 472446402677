.container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ace6e72b;
  height: 605px;

  /* use vw and calc for margin-top */
  margin-top: calc(5vw);
  margin-bottom: calc(5vw);
}
.inner_container {
  max-width: 1440px;
  width: 100%;
  height: auto;
  position: relative;
}

.bg_container {
  width: 100%;
  height: 605px;
  background: url(../../images/home/part2-bg.png) no-repeat;
  background-size: 100% 100%;
}

.bg_container img {
  width: 100%;
  height: 100%;
}

.calculator_wrapper {
  width: fit-content;
  position: relative;
  bottom: 484px;
  left: 588px;
}

.title {
  font-size: 73px;
  font-weight: 600;
  color: white;
  transform: rotate(-90deg);
  position: absolute;
  bottom: 219px;
  left: 380px;
}

.title_chi {
  position: absolute;
  font-size: 80px;
  font-weight: 600;
  color: white;
  bottom: -22px;
  left: 70px;
}

/****** mobile version ****/
.container_mob {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-top: 22vw;
}
.cal_container_mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10vw;
  margin-bottom: 20vw;
  position: relative;
  scale: 0.85;
  top: 62px;
}
.title_chi_mobile {
  width: fit-content;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  text-align: center;
  left: 175px;
  top: 36px;
}
.cal_container_bg {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: -1;
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .inner_container {
    scale: 0.8 !important;
  }

  .bg_container {
    background-size: cover;
  }
}
@media only screen and (min-width: 857px) and (max-width: 1023px) {
  .inner_container {
    scale: 0.6 !important;
  }
  .bg_container {
    background-size: cover;
  }
}

@media only screen and (min-width: 600px) and (max-width: 856px) {
  .inner_container {
    scale: 0.5 !important;
  }
  .bg_container {
    background-size: cover;
  }
}

@media (min-width: 600px) and (max-width: 850px) {
  .title_chi_mobile {
    font-size: 3rem;
    left: 46vw;
    top: 12vw;
  }
}

@media only screen and (max-width: 375px) {
  .cal_container_mobile {
    top: 48px;
  }
  .title_chi_mobile {
    left: 165px;
    top: 32px;
  }
}
@media only screen and (max-width: 350px) {
  .cal_container_mobile {
    top: 35px;
  }
  .title_chi_mobile {
    font-size: 1.5rem;
    left: 144px;
    top: 29px;
  }
}
