.video_mute_btn {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 70px;
  left: 30px;
  background-size: contain;
  border: none;
  cursor: pointer;

  font-size: 12px;
  z-index: 99;
  opacity: 1;
}
