.blog_post_page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 5rem;
  max-width: none;
  width: 100%;
}

.left_side {
  max-width: 40vw;
  margin-left: 20px;
  margin-right: 20px;
}

.right_side {
  max-width: 500px;
  margin-left: 20px;
  margin-right: 20px;
}

.left_side img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.thumbnail {
  margin-bottom: 2rem;
  cursor: pointer;
}

.thumbnail img {
  max-width: 200px;
  height: auto;
  box-shadow: 0px 0px 7px 0px #2cced175;
  margin-bottom: 1rem;
}

/* Adjust the layout for smaller screens */
@media (max-width: 768px) {
  .left_side,
  .right_side {
    flex-basis: 100%; /* Each side takes up the full width of the container */
    max-width: 100%; /* Remove the max-width restriction */
  }
}
