.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(../../images/home/backgroundcolor.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 80vh;
  color: white;
}

@media only screen and (max-width: 600px) {
  .content {
    padding: 1rem;
    text-align: center;
  }
}
