.text1 {
  font-size: 28px;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 600px) {
  .text1 {
    font-size: 23px;
  }
}
