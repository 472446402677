.anim_container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.privateloan_anim_obj1 {
  width: 886px;
  height: auto;
  position: absolute;
  top: -39px;
  left: 85px;
  overflow: hidden;
}

.privateloan_anim_obj2 {
  width: 450px;
  height: auto;
  position: absolute;
  top: -243px;
  left: 216px;
  z-index: 2;
}

.privateloan_anim_obj3 {
  width: 150px;
  height: auto;
  position: absolute;
  right: 185px;
  top: 14px;
  z-index: 5;
}
.privateloan_anim_obj4 {
  width: 120px;
  height: auto;
  position: absolute;
  top: 99px;
  right: 195px;
  z-index: 1;
}
/***********/
.largeamount_anim_container {
  display: none;
  position: relative;
}
.largeamount_anim_obj1 {
  width: auto;
  height: 764px;
  position: absolute;
  top: 0px;
  left: 150px;
  overflow: hidden;
}
.largeamount_anim_obj2 {
  width: 550px;
  height: auto;
  position: absolute;
  top: -301px;
  left: 138px;
  z-index: 2;
}
.largeamount_anim_obj3 {
  width: 100px;
  height: auto;
  position: absolute;
  top: 46px;
  right: 235px;
  z-index: 5;
}
.largeamount_anim_obj4 {
  width: 180px;
  height: auto;
  position: absolute;
  top: -33px;
  left: 388px;
  z-index: 1;
}
/*****************/
.mortgage_anim_container {
  display: none;
}

.mortgage_anim_obj2 {
  width: 450px;
  min-width: 150px;
  height: auto;
  position: absolute;
  top: -310px;
  left: 73px;
  z-index: 2;
}
.mortgage_anim_obj3 {
  width: 12vw;
  min-width: 120px;
  height: auto;
  position: absolute;
  top: 48px;
  left: 226px;
  z-index: 5;
}

@keyframes moveAlongPath {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

/* @media mobile responsive */
@media (max-width: 600px) {
  /* whole animation container */
  .privateloan_anim_container,
  .largeamount_anim_container,
  .mortgage_anim_container {
    scale: 0.5;
    position: absolute;
    top: 0px;
    left: 125px;
  }

  /* 1st anim */
  .privateloan_anim_obj3 {
    left: 375px;
    top: 17px;
  }

  .privateloan_anim_obj4 {
    top: 99px;
    left: 400px;
  }

  /* 2nd anim */

  .largeamount_anim_obj2 {
    left: 178px;
  }
  .largeamount_anim_obj3 {
    top: 46px;
    left: 384px;
  }
  /* 3rd anim */
  .mortgage_anim_obj2 {
    top: -314px;
    left: 137px;
  }
}

@media only screen and (max-width: 375px) {
  .privateloan_anim_container,
  .largeamount_anim_container,
  .mortgage_anim_container {
    scale: 0.45;
    position: absolute;
    top: 0px;
    left: 60px;
  }
}
