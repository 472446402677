.tabs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs-container p,
.tabs-container span {
  font-family: "pingfangSmall" !important;
}

.tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  /* transition: border-bottom 0.3s ease; */
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between image and label */
  margin-right: 5px;
  margin-left: 5px;
}

.tab.selected,
.tab.hover {
  border-bottom: 2px solid #7f25d3;
}

.tab-content {
  margin-top: 20px;
  padding: 10px;
  width: clamp(300px, 100%, 1000px);
}

.tab-image {
  width: 30px; /* Adjust as per your needs */
  height: 30px; /* Adjust as per your needs */
}

@media only screen and (max-width: 750px) {
  .tabs-container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .tab-container {
    flex-direction: column;
    align-items: start;
  }

  .tab {
    padding: 15px 15px 15px 15px;
    white-space: nowrap;
    cursor: pointer;
    /* transition: border-bottom 0.3s ease; */
    display: flex;
    align-items: center;
    gap: 10px; /* Adds spacing between image and label */
  }

  .tab.selected,
  .tab.hover {
    transition: 0.3s;
    border-bottom: unset;
    background-color: #7f25d3;
    color: white;
  }
}
