/** Animation **/

/* text fade in from left */

.hidden-left {
  transform: translateX(-800px);
}

.fixed {
  position: fixed;
  width: 100%;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -40%);
  z-index: 1000;
}

@keyframes fadeInLeft {
  from {
    opacity: 1;
    transform: translateX(-800px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-in-left {
  opacity: 0;
  animation: fadeInLeft 1s linear forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-element {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeUp {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-up-element {
  animation: fadeUp 0.5s ease-in-out forwards;
}

@keyframes fadeRight {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-right-element {
  animation: fadeRight 0.5s ease-in-out forwards;
}
@keyframes fadeLeft {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-left-element {
  animation: fadeLeft 0.5s ease-in-out forwards;
}

.button_hover_motion2 {
  transform: translate(0);
  transition: 0.1s;
  animation-timing-function: ease-in-out;
}

.button_hover_motion2:hover {
  transform: translate(0px, 5px);
  opacity: 0.8;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}

.floating-button {
  animation: float 3s ease-in-out infinite;
}
