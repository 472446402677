@import url("https://fonts.googleapis.com/earlyaccess/notosanstc.css");
@font-face {
  font-family: "pingfangBold";
  src: url("./font/pingfang_hk_bold.ttf");
}
@font-face {
  font-family: "pingfangSmall";
  src: url("./font/pingfang_hk_small.otf");
}

body {
  margin: unset !important;
}
div,
p,
a,
span,
h1,
h2,
h3,
h4,
h5,
button,
a label {
  /* font-family: "Noto Sans TC", sans-serif; */
  font-family: "pingfangSmall";
}

a {
  text-decoration: none !important;
  transition: 0.2s;
  /* color: inherit !important; */
}

a:hover {
}

/* .dropdown:hover .dropdown-menu {
  display: block !important;
}

.dropdown:hover .dropdown-menu.show {
  display: block !important;
}
.dropdown-menu.show {
  display: none !important;
} */

/* top menu border */

/* toggle menu */
.navbar-toggler {
  background-color: #7bd9f0a3 !important;
}

.dropdown-menu {
  --bs-dropdown-border-color: 0 !important;
}

.navbar {
  --bs-navbar-nav-link-padding-x: 1rem !important;
}

:root {
  --title_grey: #565656;
}

.space {
  height: 100px;
}

.white {
  color: white;
}
.color-p1 {
  color: #989898;
}
.color1 {
  color: #53e8ed;
}
.color2 {
  color: #054749;
}
.color3 {
  color: #575757;
}

.color4 {
  color: #10463e;
}
.color5 {
  color: #00b2b6;
}
.color6 {
  color: #108d91;
}
.color-text {
  color: #212529;
}

.alertcolor {
  color: rgb(197, 34, 34);
}

.succes {
  color: rgb(49, 245, 101);
}
.MuiFormLabel-asterisk {
  color: red;
}

.purple_btn {
  background-color: #4d2988 !important;
  color: white !important;
  border: 0;
  width: 106px;
}

.title-h1 {
  font-size: calc(1.375rem + 1.5vw);
  margin-bottom: 0.5rem;
}
.title-h3 {
  font-size: calc(1.3rem + 0.6vw);
  margin-bottom: 0.5rem;
  color: #108d91;
}

.desc-style span {
  font-size: 1rem;
  font-family: "pingfangSmall" !important;
  line-height: 1.7;
}

/** input style**/
.tone1 input {
  color: #108d91 !important;
}
.tone1 .form-control:focus {
  box-shadow: 0 0 4px 3px #108d91 !important;
  border: #108d91 1px solid !important;
}
.tone1 .form-control {
  border: #8f8f8f9c 1px solid !important;
}

.tone1 .form-control::placeholder {
  color: #c8c8c8ed !important;
}
/** end input style**/

/**banner style**/
.normal-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 56vh;
  /* box-shadow: 0px 6px 14px 2px #787676; */
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.normal-banner h1 {
  color: white;
  text-align: center;
  font-size: 3rem;
}

.normal-banner-text {
  color: #4e4e4e;
  width: 27rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10%;
}
.normal-banner-text p {
  font-size: 1rem;
}

.imageDesc-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 960px) {
  .imageDesc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* react swiper */
.swiper_arrow_left,
.swiper_arrow_right {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 0;
  position: relative;
}

.swiper_arrow_left {
  background-image: url("./images/compoients/left_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  top: -1rem;
}

.swiper_arrow_right {
  background-image: url("./images/compoients/right_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.small-container {
  max-width: 800px;
  height: 600px;
  margin: 0 auto;
}

.container-box {
  position: relative;
}
.container-box .arrow {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
.container-box .arrow.arrow-left {
  left: 0;
}
.container-box .arrow.arrow-right {
  right: 0;
}

/* primereact  */
.p-carousel-content {
  overflow: hidden !important;
}
.p-carousel-item {
  display: grid !important;
}

/* nuka-carousel */
.carousel-container {
  width: 80%;
  margin: 0 auto; /* This will center the carousel on the page */
  margin-bottom: 5rem;
}
.carousel-image {
  width: 200px;
}

.slide-arrow-left {
  border: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  /* position: relative;
  left: -3rem; */
}
.slide-arrow-right {
  border: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  /* position: relative;
  right: -3rem; */
}
.slide-arrow-img {
  width: 50px;
}

.slide-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Or specify a specific height if needed */
}

@media only screen and (max-width: 750px) {
  .carousel-image {
    width: 150px;
  }
}

/* end react swiper */

/**end banner style**/

/**override swiper style**/

/* .swiper-button-prev {
  background-image: url('./images/aboutus/banner.png');

  background-size: contain;
  width: 30px;  
  height: 30px; 
}

.swiper-button-next {
  background-image: url('./images/aboutus/banner.png');

  background-size: contain;
  width: 30px;  
  height: 30px; 
} */
/**end override swiper style **/
@media only screen and (min-width: 1200px) {
  .title-h1 {
    font-size: 2.5rem;
  }
  .title-h3 {
    font-size: 1.5rem;
  }
}

/** swiper styles **/

.video_style {
  width: 100%;
  height: auto;
  max-height: 90vh;
}

/** react pdf viewer **/

.rpv-toolbar__item button[aria-label="Download"] {
  display: none;
}
.rpv-toolbar__item button[aria-label="Open file"] {
  display: none;
}

.rpv-core__annotation-popup-wrapper {
  display: none !important;
}
