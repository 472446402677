


   .form-container {
    margin: 5rem;
    
  }

  
  .form-container .form-group {
    margin-bottom: 15px;
  }

  .form-container label{
    padding:.5rem;
    padding-left: 0;
  }  
  
  .form-container .input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin:1rem;    
  }
  .form-container input,.form-container textarea{
    margin-left: 0.5rem;
  }
  .form-container textarea{
    position: relative;
    top:1rem;
  }
  
  .form-container button {
    padding: 10px 20px;
    background-color: #f5891d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: 0.3s
  }
  
  .form-container button:hover {
    background-color: #c26d17;
  ;
  }

  .submit_btn {
    padding: 10px 20px;
    background-color: #f5891d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: 0.3s
  }

  .submit_btn:hover{
    background-color: #c26d17;
  }
  