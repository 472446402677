.loanProduct-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 16/8;
  position: relative;
}

.loanProduct-banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  z-index: 10;
  clip-path: polygon(0 0%, 100% 0, 100% 89%, 0 100%);
}

.loanProduct-banner-text {
  color: #393939;
  max-width: 350px;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 20%;
  left: 9%;
}
.loanProduct-banner-text h1 {
  font-size: 3rem;
  font-weight: 600;
}

.loanProduct-banner-text span {
  font-size: 22px;
}

hr.loanProduct-banner-divider {
  border-top: 3px solid #4e4e4e;
  width: 5rem;
  width: 75%;
  opacity: 100;
  margin-top: 2px;
}

.loanProduct-eform {
  margin: 100px 0;
  padding: 0 20px;
}

.sub_title {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.loanProduct-3steps {
  margin: 100px 0;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.loanProduct-3steps > img {
  width: clamp(350px, 100%, 500px);
  height: auto;
}

.bottom-banner {
  width: 100%;
  height: auto;
}

/********* mobile version *****/
.loanProduct-banner-text-mobile {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  position: relative;
  left: 7%;
  top: 13%;
}
.loanProduct-banner-text-mobile h1 {
  font-weight: 600;
  padding-bottom: 1rem;
}
.loanProduct-banner-text-mobile span {
  font-size: 14px;
}
.loanProduct-banner-btn-mobile {
  scale: 0.7;
  margin-left: -47px;
  margin-top: -20px;
}

@media only screen and (min-width: 601px) and (max-width: 765px) {
  .loanProduct-banner-text {
    top: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
  .loanProduct-banner-text {
    top: 26%;
  }
}
