/** button style **/
.applyBtn_banner {
  width: 200px;
  height: 50px;
  font-size: 1.7rem;
  background-color: #4cddd1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 30px;
}

.ProductPage_btn {
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(181deg, #8ef4f8 0%, #48d9de 100%);
  border: 0;
  color: black;
  border-radius: 50px;
  max-width: 300px;
  padding-left: 30px;
  padding-right: 30px;
  height: 50px;
}
.apply_btn {
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(181deg, #8ef4f8 0%, #48d9de 100%);
  border: 0;
  color: black;
  border-radius: 50px;
  max-width: 300px;
  padding-left: 80px;
  padding-right: 80px;
  height: 50px;
}

.center-applyBtn {
  font-size: 20px;
  background-color: #8c46ff;
  color: white;
  border: 0;
  border-radius: 7px;
  min-width: 7.5rem;
  min-height: 2.5rem;
  font-weight: 500;
}

.applyBtn {
  border: 0;
  padding: 0;
  width: 150px;
  height: 45px;
  font-size: 1.3rem;
  border-radius: 7px;
}

.applyBtn-lg {
  border: 0;
  padding: 0;
  width: 350px;
  height: 55px;
  font-size: 1.3rem;
  background: url("../../images/button/button.png") no-repeat;
  background-size: 100% 100%;
  color: white;

  /* media mobile */
  @media screen and (max-width: 500px) {
    width: 270px;
  }
}
.goBtn {
  border: 0;
  padding: 5px 15px;
  font-size: 18px;
  border-radius: 20px;
}

.applyBtn-big {
  border: 0;
  padding: 0;
  width: 190px;
  height: 50px;
  color: white;
  font-size: 1.5rem;
  border-radius: 7px;
}
.btn-disabled,
.btn-disabled:focus,
.btn-disabled:visited,
.btn-disabled:active {
  opacity: 0.3;
  cursor: not-allowed;
  background: black !important;
}
.fullBtn {
  border: 0;
  padding: 0;
  width: 184px;
  height: 50px;
  font-size: 1.5rem;
  border-radius: 7px;
  margin-right: 2rem;
}

.animatedBtn:hover {
  opacity: 0.8;
  transition: all 0.2s ease-in;
  transform: translateY(2px);
}
.animatedBtn:active {
  opacity: 1;
}

.color-orange {
  color: white;
  background: linear-gradient(0deg, #ff8812 0%, #ffac54 100%);
}
.color-orange:active {
  background: linear-gradient(0deg, #d47211 0%, rgb(222, 150, 73) 100%);
}

.color-blue {
  color: white;
  background: linear-gradient(87deg, #2ccfd2 0%, #2cced1 100%);
}
.color-blue:active {
  background: linear-gradient(87deg, #24a6a9 0%, #25abad 100%);
}

.menu-login {
  background-image: url(../../images/home/Login.png);
  background-size: 100% 100%;
  border: 0;
  background-color: unset;
  width: 106px;
  height: 52px;
  position: relative;
}
.menu-login span {
  position: absolute;
  bottom: 1rem;
  right: 20px;
  font-weight: 700;
}

.menu-apply {
  background: linear-gradient(90deg, #8361f4 0%, #80d4e6 100%);
  border: 0;
  border-radius: 50px;
  width: 136px;
  height: 47px;
  margin-top: -3px;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}
.menu-apply:hover {
  opacity: 0.8;
}

.dashboard_btn {
  padding: 4px 20px;
  background-color: #ff8812;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
}
.dashboard_btn:hover {
  background-color: #d27212;
}

.loginBtn {
  border: 0;
  padding: 0;
  width: 100%;
  height: 38px;
  font-size: 1rem;
  border-radius: 70px;
}

@media only screen and (max-width: 1199px) {
  .menu-login span {
    bottom: 1.1rem;
    right: 21px;
  }
}

@media only screen and (max-width: 1023px) {
  .applyBtn {
    width: 120px;
    height: 35px;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 800px) {
  .menu-apply {
    margin-top: 8px;
    width: 120px;
    height: 35px;
  }
  .menu-apply span {
    font-size: 1rem;
  }
  .ProductPage_btn {
    width: 270px;
    height: 50px;
    font-size: 1rem;
    font-weight: 600;
  }
}
@media only screen and (max-width: 350px) {
  .menu-apply {
    margin-top: 8px;
    width: 100px;
    height: 35px;
  }
}
