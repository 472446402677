.hidden {
  visibility: hidden;
}
.shown {
  visibility: visible;
}

.item_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home_section1 {
  width: 100%;
  /* clip-path: polygon(0 0%, 100% 0, 100% 89%, 0 100%); */
}

.home_section2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: url("../../images/home/backgroundcolor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99;
}
.home_section3 {
}
.home_section4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.fixedSection {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
}
.absolueSection {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 100;
}
.absolueSection2 {
  position: absolute;
  top: 1020px;
  width: 100%;
  z-index: 100;
}
.notshown {
  display: none;
}

.grid-container {
  word-wrap: break-word;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(530px, auto);
}

.grid-container::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../../images/home/diamond.png);
  background-position: center center;
  background-repeat: no-repeat;
  place-self: center;
  margin-top: 1.5rem;
}

.grid-item {
  display: grid;
  place-items: center;
}

.grid-item-1 {
  background-color: #f9d49f;
}

.grid-item-2 {
  background-color: white;
}
.grid-item-3 {
  background-color: white;
}
.grid-item-4 {
  background-color: #544b6a;
}

.section2_mobile {
  display: flex;
  flex-direction: column;
}

.features_container {
  word-wrap: break-word;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(430px, auto);
  width: 100%;
  place-items: center;
}

.animation {
  width: clamp(300px, 20ch, 500px);
}

.slider-prev-btn,
.slider-next-btn {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.slider-prev-btn img,
.slider-next-btn img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.slider-prev-btn img:hover,
.slider-next-btn img:hover {
  opacity: 0.5;
}

@media only screen and (min-width: 1920px) {
}

@media only screen and (max-width: 800px) {
  .absolueSection {
    position: absolute;
    width: 100%;
    z-index: 100;
    padding-top: 77px;
  }
  .absolueSection2 {
    position: absolute;
    top: 1180px;
    width: 100%;
    z-index: 100;
  }

  .fixedSection {
    position: fixed;
    top: 0px;
    padding-top: 20px;
    width: 100%;
    z-index: 100;
  }

  .home_section2 {
  }
  .home_section3 {
    width: 100%;
    background: unset;
  }
}

@media only screen and (min-width: 801px) {
  .home_section3 {
    margin-bottom: 350px;
  }
}
