.popupOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background: #e6d9f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1000;
  transform: translateY(-100%); /* Start above the viewport */
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0; /* Initial state hidden */
}

.popupOverlay.visible {
  transform: translateY(0); /* Slide down into view */
  opacity: 1;
}

.icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.popupContent {
  display: flex;
  flex-direction: column;
}

.popupContent span {
  font-weight: 600;
}

.row {
  display: flex;
  align-items: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: black;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.moreInfoButton {
  background: linear-gradient(90deg, #80d4e6 0%, #8361f4 100%);
  color: #fff;
  padding: 6px 29px;
  border: none;
  border-radius: 8px;
}

/* media mobile */
@media (max-width: 768px) {
  .popupContent span {
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .popupContent span {
    font-size: 12px;
  }
  .moreInfoButton {
    padding: 6px 15px;
  }
}
