.container {
  width: 100%;
  max-width: 900px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/homePart2Box/part2-box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 5rem;
  padding: 40px 25px 20px 25px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.container_mob {
  width: 100%;
  max-width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/home/part-1bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 1rem;
  padding: 40px 25px 20px 25px;
}

.carousel_style ul {
  position: relative;
  left: 11px;
}

.carouselItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 66%;
  max-width: 900px;
  height: 350px;

  background-image: url("../../images/homePart2Box/part2-box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 2rem;

  position: relative;
}

.carouselItem h3,
.carouselItem p {
  color: white;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.carouselItem p {
  font-size: 15px;
}

.container_mob button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* background-color: #fff; */
}

.container_mob
  .p-carousel
  .p-carousel-indicators
  .p-carousel-indicator.p-highlight
  button {
  background-color: red !important;
}

.container_mob ul {
  padding: 0;
}
