.container {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container > span {
  font-size: 12px;
}

.items_wrapper {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
}

.item_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item_container span {
  font-size: 20px;
}

.item_container h1 {
  background: linear-gradient(90deg, #2ad2d7, #5ef1f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 600px) {
  .container {
    margin-top: 50px;
  }
  .items_wrapper {
    width: 90%;
  }
  .item_container span {
    font-size: 16px;
  }
  .item_container h1 {
    font-size: 20px;
  }
}
/* for 320px */
@media only screen and (max-width: 320px) {
  .items_wrapper {
    flex-wrap: wrap;
  }
}
