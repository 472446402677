.privateLoan-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  background-image: url(../../images/privateloan/bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
