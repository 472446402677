.wtsapp-container {
  z-index: 99;
  width: 49px;
  height: 49px;
  background-color: #46c756;
  display: flex;

  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.wtsapp-icon {
  width: 35px;
  height: auto;
}
