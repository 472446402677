.container {
  border: 0;
  padding: 0;
  background-color: #102039;
}

.accordion_container a {
  background-color: #102039;
  color: white;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  /* boder bottom line color grey */
  border-bottom: 1px solid #5e5e5e;
}

.answer > div {
  background-color: #05254c;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #5e5e5e;
}

.answer span {
  line-height: 25px;
}
