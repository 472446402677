.profile-container {
  display: flex;
  justify-content: center;
}

.profile {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profile-container h4 {
  align-self: center;
}
.back_button {
  width: 35px;
  height: 26px;
  transition: 0.3s;
  cursor: pointer;
}
.back_button:hover {
  opacity: 50%;
}
@media only screen and (max-width: 600px) {
  /* .profile-sector {
    width: 200px;
  } */
}
