.remarks {
  font-size: 10px;
  padding-top: 2rem;
}

.container {
  width: 100%;
  max-width: 582px;
  min-width: 572px;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #091933ad;
  padding: 0px 40px 20px 40px;
}

.upperSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.leftSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align the content to the left */
  width: 50%;
  padding-right: 20px;
  padding-top: 50px;
}
.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.item_title {
}

.item_title span {
  margin-right: 1rem;
}

.money_input {
  display: flex;
  background: url("../../images/calculator/calculatorInputbox.png") no-repeat
    center center;
  background-size: 100% 100%;
  height: 60px;
  border-radius: 6px;
  width: 100%;
  justify-content: center;
  text-align: center;
  position: relative;
}
.money_input span {
  color: white;
  align-self: center;
  z-index: 5;
  font-size: inherit;
}

.money_input input {
  background: transparent;
  border: 0;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 45%;
  font-size: inherit;
  position: relative;
  z-index: 10; /* To make sure it stays above the background */
}

.money_input input:focus-visible {
  outline: 0px;
}

.slider_wrapper {
  width: 100%;
}

.resultSection {
  display: flex;
  flex-direction: column;
  background: url("../../images/calculator/calculatorRepaymentBox.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%; /* Ensure the background image covers the whole div */
  height: 250px; /* Adjust height to match the image's aspect ratio */
  border-radius: 6px;
  width: 100%;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;
  position: relative;
  top: 55px;
}

.resultSection h4 {
  font-size: 1.2vw;
}
.resultSection h3 {
  font-size: 1.5vw;
}

.row_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row_container img {
  margin-top: -7px;
  padding-right: 10px;
}

.space {
  height: 30px;
}

@media only screen and (max-width: 850px) {
  .container {
    background: unset;
    min-width: unset;
    padding: 0;
  }
  .upperSection {
    padding: 0 1rem 1rem 1rem;
  }
  .leftSection {
    padding-right: 12px;
    padding-left: 8px;
  }

  .item_title span {
    font-size: 16px;
  }
  .money_input {
    width: 100%;
    height: 43px;
  }
  .money_input span {
    font-size: 1rem;
  }
  .money_input input {
    width: 40%;
    font-size: 1rem;
  }

  .slider_wrapper {
    width: 100%;
  }

  .resultSection {
    width: 200px;
    height: 200px;
    top: 62px;
  }
  .resultSection h4 {
    font-size: 1rem;
  }
  .resultSection h3 {
    font-size: 1.2rem;
  }
  .remarks {
    padding: 2rem 2rem 0rem 2rem;
  }
}

@media (max-width: 850px) and (min-width: 501px) {
  .rightSection {
    width: 50%;
  }
  .resultSection {
    left: 48px;
  }
}

@media (max-width: 500px) {
  .rightSection {
    width: 58%;
  }
}
