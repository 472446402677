.boxSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formContainer {
  flex-grow: 1;
  max-width: 500px;
}

.customTextField {
  max-width: 500px;
  width: 100%;
}

.customButton {
  max-width: 500px;
  width: 100%; /* Ensure full width within the container */
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  border-radius: 50px !important;
  background: linear-gradient(90deg, #30babd 0%, #7cdbdd 100%);
  box-shadow: none !important;
  height: 50px !important;
  font-size: 16px !important;
}
.customButton:hover {
  background: linear-gradient(90deg, #2ba4a6 0%, #6fc3c4 100%);
}
