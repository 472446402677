.container {
  display: flex;
  justify-content: center;
  width: 150px;
  height: auto;
  background-color: #f5881f;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  margin: 2rem;
  cursor: pointer;
}

.container:hover {
  background-color: #cf721b;
}
