/* Wrapper to center the whole content */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh; /* Ensures it takes up full viewport height */
  z-index: 99;
}

/* Main container for left and right sections */
.container {
  display: flex;
  place-content: center;
  align-items: center;
  width: fit-content;
  max-width: 1800px;
  color: white;
}

.leftSection {
  flex: 1; /* Left section takes up equal space */

  display: flex;
  flex-direction: column;
  align-items: end;
  align-self: start;
}

.rightSection {
  flex: 1; /* Right section takes up equal space */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 60px;
}

.slogan {
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding-top: 4rem;
}

.applyButton {
  padding: 10px 20px;
  background-color: #0dcaf0;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.applyButton:hover {
  background-color: #0b95c2;
}

@media only screen and (max-width: 1280px) {
  .container {
    scale: 0.8;
  }
}

@media (max-width: 992px) {
  .container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    scale: 1;
  }
  .wrapper {
    height: auto;
  }

  .leftSection {
    align-items: center;
    align-self: center;
    height: auto;
    width: 100%;
  }

  .rightSection {
    background-color: #091933ad;
    height: auto;
    padding-left: 0;
    align-items: center;
    width: 100%;
  }

  .slogan {
    width: 85%;
    padding-top: 30px;
  }
  .slogan h1 {
    font-size: 2rem;
    text-align: center;
  }

  .slogan h6 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) and (min-width: 501px) {
  .leftSection {
    padding-top: 5rem;
  }
}

@media (max-width: 600px) {
  .leftSection {
    padding-top: 5rem;
  }
  .slogan h1 {
    font-size: 6vw;
  }

  .slogan h6 {
    font-size: 3vw;
  }
}
